import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import "../../components/resume.css"
import resumeDoc from "./resume.docx"


const Title = styled.h1`
    color: black;
    font-size: 36pt;
    font-style: bolder;
    font-family: 'Noto Serif';
    line-height: .5em;    
`

const Subtitle = styled.p`
    color: black;
    font-weight: normal;
    font-size: 14pt;
    font-family: 'Open Sans';
`

const Main = styled.main`
    width: calc(100% - 2.5in);
    max-width: 6.5in;
    min-width: 5in;
    padding-right: .75in;
`

const Aside = styled.aside`
    margin: 0;
    padding: 0;
    margin-top: 0;    
    width: 2.5in;
`

const SectionHeading = styled.h2`
    color: #3d729d;    
    text-transform: uppercase;
    font-family: Lato;
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 1rem;
`

const ProjectTitle = styled.h3`
    font-family: "Bitter";    
    font-size: 11pt;
    font-weight: normal;
    margin-bottom: 0.25rem;
    line-height: 1rem;
`

const ProjectSubtitle = styled.p`
    font-family: "Lato";
    font-size: 10pt;
    text-transform: uppercase;
    font-weight: normal;
    color: dimgray;
    line-height: 1rem;
    margin-bottom: .5rem;
`

const Anchor = styled.a`
    color: #1155cc;    
`;

const Paragraph = styled.p`
    color: dimgray;
    font-family: "Roboto Slab";
    font-size: 11pt;
    white-space: normal;
    line-height: 1.2rem;    
    margin-bottom: 1rem;
`;

const ProjectDescription = styled.p`
    color: dimgray;
    font-family: "Roboto Slab";
    font-size: 11pt;
    white-space: normal; 
    line-height: 1rem;    
`;

class Resume extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mounted: false
        }        
        this.strayDesc = "";
        this.superDesc = "";
        this.ukuleleDesc = "";
        this.labsDesc = "";
    }

    componentDidMount (){
        document.body.style.backgroundColor = "whitesmoke";
        document.body.style.color = "black";
        document.body.style.paddingTop = "1in";
        document.body.style.marginLeft = "1in";
        document.body.style.marginRight = "1in";
        document.body.style.overflow = "auto";        
        this.setState({mounted: true}); 

        const parsedDoc = new DOMParser().parseFromString(resumeDoc, "text/html");  
        const heading2list = parsedDoc.getElementsByTagName("h2"); 
        
        for(let element of heading2list){
            this.findNode(element, "Stray", "strayDesc");
            this.findNode(element, "Super", "superDesc");
            this.findNode(element, "Ukulele", "ukuleleDesc");
            this.findNode(element, "Labs", "labsDesc");
        };
    }

    findNode(element, nameMatch, member){
        const name = element.textContent;
        if (name.includes(nameMatch)){
            this[member] = element.nextElementSibling.nextElementSibling.textContent;
        }
    }

    render(){
        return (                
            <React.Fragment>                
                <SEO title="Resume"/>
                { this.state.mounted ?
                    <React.Fragment> 
                        <header style={{/* width: "100%", */ display: "flex", flexWrap: "wrap"}}>
                            <section aria-label="Name" style={{ maxWidth: "6.5in", minHeight: "1.25in", width: "calc(100% - 2.5in)", minWidth: "5in"}}>
                                <Title>Gabe Troyan</Title>
                                <Subtitle>Gameplay Programmer & Systems Engineer</Subtitle>
                            </section>
                            
                            <section aria-label="Contact Info" style={{fontSize: "11pt", lineHeight: ".3rem", minHeight: "1.2in", fontFamily: "Noto Sans", width: "2.5in" /* float: "left" *//*  maxWidth: "2.5in" */}}>
                                <ul style={{margin: 0, padding: 0, listStyleType: "none"}}>
                                    <li>Burlington, VT 05401</li>                        
                                    <strong> 
                                        <li><Anchor href="mailto:Gabe@JusticePorkGames.com">Gabe@JusticePorkGames.com</Anchor></li>
                                        <li><Anchor href="https://linkedin.com/in/gabe">LinkedIn.com/in/Gabe</Anchor></li>
                                        <li><Anchor href="https://gabe.ws">Gabe.ws</Anchor></li>
                                    </strong>
                                </ul>
                            </section>                            
                        </header>
                        <section aria-label="Resume Body" style={{display: "flex", flexWrap: "wrap"}}>
                            <Main>
                                <section style={{marginBottom:"2rem"}}>
                                    <SectionHeading>Collaborative Experience</SectionHeading>
                                    <header>
                                        <ProjectTitle><strong>Stray: The Wayward Path</strong> &mdash; <em>Unreal Engine 4 Game</em></ProjectTitle>
                                        <ProjectSubtitle>
                                            JUSTICE PORK GAMES, Team Size: 15, SEPTEMBER 2018 – APRIL 2019 <br/> 
                                            Lead, Gameplay, Animation, Systems Programmer
                                        </ProjectSubtitle>
                                    </header>
                                    <ProjectDescription>
                                        {this.strayDesc} <br/>
                                        <Anchor href="https://justiceporkgames.com?p=intro">JusticePorkGames.com</Anchor>
                                    </ProjectDescription>

                                    <header>
                                        <ProjectTitle><strong>Super Sibling Smasherino</strong> &mdash; <em>Unity Game</em></ProjectTitle>
                                        <ProjectSubtitle>GLOBAL GAME JAM® 2019, Team Size: 5, JANUARY 25 – 27, 2019 <br />
                                            Gameplay, Systems Programmer
                                        </ProjectSubtitle>
                                    </header>
                                    <ProjectDescription>
                                        {this.superDesc} <br/> 
                                        <Anchor href="https://GabeEddyT.Itch.io/super-sibling-smasherino">GabeEddyT.Itch.io/super-sibling-smasherino </Anchor>
                                    </ProjectDescription>

                                    <header>
                                        <ProjectTitle><strong>‘Ukulele Me‘e</strong> &mdash; <em>Unity Game</em></ProjectTitle>
                                        <ProjectSubtitle>
                                            POCKET DIMENSION GAMES, Team Size: 10, JANUARY – MAY 2018 <br /> 
                                            Lead, Gameplay, Systems Programmer
                                        </ProjectSubtitle>
                                    </header>
                                    <ProjectDescription>
                                        {this.ukuleleDesc} <br/>
                                        <Anchor href="https://gabeeddyt.itch.io/ukulele">GabeEddyT.Itch.io/Ukulele</Anchor>
                                    </ProjectDescription>
                                </section>
                                <section style={{marginBottom:"2rem"}}>
                                    <SectionHeading>Independent Projects</SectionHeading>
                                    <header>
                                        <ProjectTitle><strong>What Labs Are Open?</strong> &mdash; <em>Single-Page Application </em></ProjectTitle>
                                        <ProjectSubtitle>JAMSTACK (HTML, CSS, JS) Project, AUGUST 2018 – CURRENT DAY</ProjectSubtitle>
                                    </header>
                                    <ProjectDescription>
                                        {this.labsDesc} <br/>
                                        <Anchor href="https://labs.gabe.ws">Labs.Gabe.ws</Anchor>
                                    </ProjectDescription>
                                </section>
                                <section style={{marginBottom:"2rem"}}>
                                    <SectionHeading>Education</SectionHeading>
                                    <header>
                                        <ProjectTitle><strong>Champlain College,</strong> Burlington, VT<br/> <em>Bachelor of Science in Game Programming, I.T.S. Division</em></ProjectTitle>
                                        <ProjectSubtitle>AUGUST 2015 – DECEMBER 2019</ProjectSubtitle>
                                    </header>
                                </section>
                            </Main>
                            <Aside>
                                
                                <section style={{marginBottom:"2rem"}}>
                                    <SectionHeading>Skills</SectionHeading>
                                    <Paragraph><strong>HTML, CSS, ECMAScript, TypeScript, JS/TSX (React), jQuery, PHP, Node.js, MariaDB, MySQL<br/> C, C++, C#, Assembly, Python, Java, Ruby, Bash, GLSL, HLSL</strong></Paragraph>
                                    <Paragraph><strong>Visual Studio 2019 <br/> Visual Studio Code <br/> Eclipse</strong></Paragraph>
                                    <Paragraph><strong>Windows, macOS, Linux <br /> (Debian, Ubuntu, Mint, Solus & friends)</strong></Paragraph>
                                    <Paragraph><strong>Unreal Engine 4<br/> Unity</strong></Paragraph>
                                    <Paragraph><strong>Agile, Scrum</strong></Paragraph>
                                    <Paragraph><strong>Source Control</strong> <br/>Git, Mercurial, SVN<br/> Github, Bitbucket, Gitlab</Paragraph>
                                    <Paragraph><strong>Video & Photo editing </strong> <br/>Adobe Premiere, Photoshop, Illustrator, GIMP</Paragraph>                    
                                </section>
                                <section>
                                    <SectionHeading>Awards & Honors</SectionHeading>
                                    <Paragraph><strong>Best Laughs to Lines Ratio</strong> <br/>Champlayers, April 2017</Paragraph>                   
                                </section>
                            </Aside>
                        </section>
                    </React.Fragment>
                :
                    <></>
                }
            </React.Fragment>
        );
    }
}
export default Resume;
